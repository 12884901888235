import Container from '@material-ui/core/Container';
import { Workflow } from 'components/workflow/workflows/types';
import LoadingIndicator from 'core-ui/components/LoadingIndicator';
import { useAxios } from 'hooks';
import React, { useEffect, useState } from 'react';
import { buildWorkflowUrl } from 'utils/common/links';
import { flattenStages } from '../helpers/workflowStage.helpers';

const WorkflowTest = () => {
	const [workflows, setWorkflows] = useState<Workflow[]>([]);
	const workflowStore = useAxios<Workflow>('workflows');

	useEffect(() => {
		workflowStore.findAll().then(setWorkflows);
		//eslint-disable-next-line
	}, []);

	const stageExists = (workflow: Workflow, targetStageId: string) => {
		return (
			flattenStages(workflow).filter((stage) => targetStageId === stage._id)
				.length > 0
		);
	};

	const hasBadTransition = (workflow: Workflow) => {
		const stages = flattenStages(workflow);
		let result = false;

		stages.forEach((stage) => {
			stage.transitions.forEach((tx) => {
				if (!stageExists(workflow, tx.targetStage)) result = true;
			});
		});
		return result;
	};

	const problemWorkflows = workflows.filter((workflow) =>
		hasBadTransition(workflow)
	);

	return (
		<Container>
			{!problemWorkflows.length && <LoadingIndicator />}
			<div>
				<h1>Workflow Tester</h1>
				<p>Workflows found: {workflows.length}</p>
				<p>Problem Workflows: {problemWorkflows.length}</p>
				<hr />
				{problemWorkflows.map((workflow, index) => (
					<div key={`${workflow._id}__${index}`}>
						<h5>
							{' '}
							<a href={buildWorkflowUrl(workflow)} target="_workflow">
								{index + 1} : : {workflow.title}{' '}
							</a>
						</h5>
						<div>Template Used: {workflow.templateUsed}</div>
						<ol>
							{flattenStages(workflow, true).map((stage) => (
								<li
									key={`${workflow._id}--${
										stage._id
									}_${index}_${Math.random()}`}
								>
									{stage._id} {stage.title} has {stage.transitions.length}{' '}
									transitions
									<ol>
										{stage.transitions.map((tx, index) => (
											<li
												key={`${workflow._id}-${stage._id}-${index}`}
												className={
													stageExists(workflow, tx.targetStage)
														? 'text-success'
														: 'text-danger'
												}
											>
												{tx.type} to {tx.targetStage}{' '}
												{stageExists(workflow, tx.targetStage)}
											</li>
										))}
									</ol>
								</li>
							))}
						</ol>
					</div>
				))}
			</div>
		</Container>
	);
};

export default WorkflowTest;
