import React, { useEffect, useState } from 'react';
import { Col, Container, Row, UncontrolledTooltip } from 'reactstrap';

import { sanitizeForDOMElementId } from '../../../utils/common';
import themeStore from '../../../core-ui/models/ThemeStore';

import AssetCard from './asset-card.component';
import { _logError } from '../../../utils/common/log';
import { uniq } from 'lodash';
import {
	AssetVersion,
	Workflow,
} from '../../workflow/workflows/types/workflow.types';
import { useAssetHelper } from './helpers/useAssetHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const ZippedAssetsColumn = ({
	asset,
	damAsset,
	workflow,
	onDelete,
}: {
	onDelete: () => void;
	asset?: {
		fileName: string;
		bytes: any;
		fileSizeBytes: number;
		createdAt: Date;
	};
	damAsset?: AssetVersion;
	workflow?: Workflow;
	setAsset: (asset: AssetVersion) => void;
}) => {
	const { downloadFileWithinAsset } = useAssetHelper();

	const onDownloadClick = React.useCallback(
		async (fileName: string) => {
			if (damAsset) await downloadFileWithinAsset(damAsset?._id, fileName);
		},
		//eslint-disable-next-line
		[damAsset]
	);
	return asset ? (
		<Col lg={4} sm={6} className="mb-4">
			<UncontrolledTooltip
				target={sanitizeForDOMElementId('tooltip_' + asset.fileName)}
			>
				{asset.fileName}
			</UncontrolledTooltip>
			<div id={sanitizeForDOMElementId('tooltip_' + asset.fileName)}>
				<AssetCard
					disableDetail
					isLinkEnabled
					showUploadedDate={false}
					onDelete={onDelete}
					showDownloadOption
					downloadOption={(fileName) => onDownloadClick(fileName)}
					type="uploaded"
					workflowId={workflow?._id}
					assetVersion={
						({
							...asset,
							extension: asset.fileName.substring(
								asset.fileName.lastIndexOf('.') + 1,
								asset.fileName.length
							),
							previewURL: `data:image/png;base64,${asset.bytes}`,
						} as unknown) as AssetVersion
					}
				/>
			</div>
		</Col>
	) : null;
};
const ZippedAssetsContent = ({
	setAsset,
	asset,
	workflow,
}: {
	setAsset: (asset: AssetVersion) => void;
	asset?: AssetVersion;
	workflow?: Workflow;
}) => {
	const { zippedAssets } = useAssetHelper();
	const [relatedIDs, setRelatedIDs] = useState<
		Array<{
			fileSizeBytes: number;
			fileName: string;
			bytes: any;
			createdAt: Date;
		}>
	>();
	const [, setAssets] = useState<AssetVersion[]>();

	// todo use an 'observable' way of getting these instead of useEffect
	const setAssetCallBack = (asset: AssetVersion) => {
		setAsset(asset);
		setRelatedIDs(undefined);
		setAssets(undefined);
	};

	useEffect(() => {
		if (asset) zippedAssets(asset).then(setRelatedIDs).catch(_logError);
		//eslint-disable-next-line
	}, [asset]);

	if (relatedIDs?.length) {
		return (
			<>
				<p>Zipped {themeStore._.asset.toLowerCase()}s</p>
				<hr />
				<Row>
					{relatedIDs &&
						relatedIDs?.length > 0 &&
						uniq(relatedIDs).map((relatedAsset) => (
							<ZippedAssetsColumn
								damAsset={asset}
								setAsset={setAssetCallBack}
								onDelete={() =>
									setRelatedIDs([
										...relatedIDs?.filter(
											(a) => a.fileName !== relatedAsset.fileName
										),
									])
								}
								key={relatedAsset.fileName}
								asset={relatedAsset}
							/>
						))}
				</Row>
			</>
		);
	} else if (relatedIDs) return <p>No zipped assets found</p>;
	return (
		<p>
			Loading zipped contents{' '}
			<FontAwesomeIcon icon={faSpinner} className={'fa fa-spin spin'} />
		</p>
	);
};

export const ZippedAssetsRow = ({
	setAsset,
	asset,
	workflow,
}: {
	setAsset: (asset: AssetVersion) => void;
	asset?: AssetVersion;
	workflow?: Workflow;
}) => {
	console.log(asset);
	const render = () => (
		<Container className="mt-5 px-0">
			<ZippedAssetsContent
				setAsset={setAsset}
				workflow={workflow}
				asset={asset}
			/>
		</Container>
	);

	return render();
};
