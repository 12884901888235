import { useAuthContext } from 'utils/auth';
import { useGroupContext } from 'utils/auth';
import React from 'react';
import { Card } from 'reactstrap';
import { canEditWorkflowCollection } from 'components/workflow/workflows/helpers';
import DeleteEntityButton from '../../../delete-entity-button.component';
import { ListColumn, makeColumns } from '../../../list-column.component';
import ListTable from '../../../list-table.component';
import { CellLink } from '../../../tables.styled-components';
import { useAxios } from '../../../../hooks';
import themeStore from '../../../../core-ui/models/ThemeStore';
import { WorkflowCollection } from '../../workflows/types/workflow.types';

// constants
const projectTerm = themeStore._.workflowCollection;

// interface
interface Props {
	collections: WorkflowCollection[];
}

// component
const WorkflowCollectionListTable = (props: Props) => {
	const { collections } = props;

	const collectionStore = useAxios<WorkflowCollection>('projects');
	const { entities: users, currentUser } = useAuthContext();
	const { entities: groups } = useGroupContext();
	const listOwnerNames = (owners: any[]) => {
		return owners
			.map((owner, index) => {
				const isGroup = groups?.some((m) => m._id === owner._id);
				if (isGroup) {
					return `${groups?.find((m) => m._id === owner._id)?.title} ${
						index === owners.length - 1 ? '' : ', '
					}`;
				} else {
					const user = users?.find((m) => m._id === owner._id);
					return `${user?.givenName} ${user?.familyName} ${
						index === owners.length - 1 ? '' : ', '
					}`;
				}
			})
			.join(' ');
	};
	const collectionListColumns: ListColumn<WorkflowCollection>[] = makeColumns([
		{
			label: 'Name',
			prop: (c: WorkflowCollection) => (
				<CellLink to={`./${c._id}`}>{c.title}</CellLink>
			),
		},
		{
			label: `${themeStore._.owner}(s)`,
			prop: (c: WorkflowCollection) => (
				<p>{listOwnerNames(c.owners as any[])}</p>
			),
		},
		{
			label: `${themeStore._.workflow} count`,
			prop: (c: WorkflowCollection) => <p>{c.workflows.length}</p>,
		},
		{
			label: '',
			prop: (c: WorkflowCollection) =>
				c.workflows.length === 0 &&
				canEditWorkflowCollection(c, currentUser) ? (
					<DeleteEntityButton
						entity={c}
						deleteOne={async (entity: any) => {
							await collectionStore.deleteOne(entity._id);
							return;
						}}
						entityName={projectTerm}
					/>
				) : null,
		},
	]);

	const render = () => (
		<Card>
			<ListTable
				columns={collectionListColumns}
				rows={collections ? collections : []}
				noResultsLabel={`No ${projectTerm.toLowerCase()}s yet`}
			/>
		</Card>
	);

	return render();
};

export default WorkflowCollectionListTable;
