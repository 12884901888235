import React, { createContext } from 'react';

type Context = {
	visibleState: 'visible' | 'hidden';
	setVisibilityState: (type: 'visible' | 'hidden') => void;
};

const noop = (type: 'visible' | 'hidden') => {};
const SidebarContext = createContext<Context>({
	visibleState: 'visible',
	setVisibilityState: noop,
});

export const SidebarContextProvider = ({
	children,
}: {
	children: React.ReactNode | React.ReactNode[];
}) => {
	const [state, setState] = React.useState<'visible' | 'hidden'>('visible');
	const updateVisibleState = (newState: 'visible' | 'hidden') => {
		console.log(`new state`, +newState);
		setState(newState);
	};
	return (
		<SidebarContext.Provider
			value={{ visibleState: state, setVisibilityState: updateVisibleState }}
		>
			{children}
		</SidebarContext.Provider>
	);
};

export const useSidebarContext = () => {
	return React.useContext(SidebarContext);
};
