import React, { useState } from 'react';
import { Form, FormGroup } from 'reactstrap';
import {
	allStagesHaveOwner,
	flattenStages,
} from 'components/workflow/workflows/helpers';
import { UserGroup } from '../../../../accounts/types';
import { useAuthContext, User } from '../../../../../utils/auth';
import { useGroupContext } from '../../../../../utils/auth';
import { LabeledSelect, SubmitButton } from '../../../../forms';
import { useModalCreator } from '../../../../../utils/ModalStack';
import { getWorkflowBoolean } from '../../helpers/workflowBoolean.helpers';
import { Flattenable, Workflow } from '../../types/workflow.types';
import { StatusEnum } from '../../types/workflowStatus.types';
import UpdateWorkflowStatusDialog from './update-workflow-status-dialog.component';

const workflowStatus: Record<string, string> = {
	pipeline: 'pipeline',
	active: 'active',
	paused: 'paused',
	cancelled: 'cancelled',
};

const canOwnerActOnWorkflow = (
	workflow: Workflow,
	groupsForCurrentUser: UserGroup[],
	currentUser: User
) => {
	return (
		getWorkflowBoolean(
			'isOwnedByCurrentUser',
			workflow,
			groupsForCurrentUser,
			currentUser
		) ||
		getWorkflowBoolean(
			'isCreatedByCurrentUser',
			workflow,
			groupsForCurrentUser,
			currentUser
		) ||
		currentUser?.isAdmin
	);
};
type Props = {
	workflow: Workflow;
	onUpdated: (wf: Workflow) => void;
};
const UpdateWorkflowStatusForm = (props: Props) => {
	const { workflow, onUpdated } = props;
	const all_stages_have_owner = allStagesHaveOwner(workflow);

	console.log('ALL STAGES HAVE OWNER', all_stages_have_owner);
	const modalStack = useModalCreator();
	const [status, setStatus] = useState(workflow?.status);
	const { groupsForCurrentUser } = useGroupContext();
	const { currentUser } = useAuthContext();
	const openWorkflowStatusModal = () => {
		modalStack.addModal(
			<UpdateWorkflowStatusDialog
				onUpdated={onUpdated}
				workflow={workflow}
				status={status as StatusEnum}
			/>
		);
	};

	const renderStatusOptions = () => (
		<>
			{((workflow?.canBeActivated &&
				canOwnerActOnWorkflow(workflow, groupsForCurrentUser, currentUser)) ||
				currentUser.isAdmin) &&
			all_stages_have_owner ? (
				<option value={StatusEnum.active}>Active</option>
			) : null}
			{StatusEnum.active === workflow?.status &&
				(canOwnerActOnWorkflow(
					workflow as Workflow,
					groupsForCurrentUser,
					currentUser
				) ||
					currentUser.isAdmin) && (
					<option value={StatusEnum.paused}>Paused</option>
				)}
			{(workflow?.canBeCancelled &&
				canOwnerActOnWorkflow(workflow, groupsForCurrentUser, currentUser)) ||
			currentUser.isAdmin ? (
				<option value={StatusEnum.cancelled}>Cancelled</option>
			) : null}
		</>
	);

	return (
		<Form>
			<FormGroup>
				<LabeledSelect
					id="workflowStatus"
					label=""
					name="status"
					required
					defaultValue=""
					onChange={(e) => setStatus(workflowStatus[e.target.value])}
				>
					<option disabled value="">
						Select a new status
					</option>
					{renderStatusOptions()}
				</LabeledSelect>
			</FormGroup>
			<SubmitButton
				onClick={openWorkflowStatusModal}
				label="Update status"
				className="mt-0"
			/>
		</Form>
	);
};

export default UpdateWorkflowStatusForm;
