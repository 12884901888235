import {
	faEdit,
	faFileUpload,
	faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RomeSwal } from 'components/alert';
import { SecondaryButton } from 'components/buttons.styled-components';
import { AnchorSpan } from 'components/dashboard.component/components/proxy-panel.styled.component';
import { NotificationsContext } from 'components/notifications';
import {
	InputSlot,
	Stage,
	WorkflowTemplate,
} from 'components/workflow/workflows/types';
import { useTemplateContext } from 'context/useTemplateContext';
import { flattenDeep } from 'lodash';
import React, { useContext, useState } from 'react';
import { CardBody, Container, Row } from 'reactstrap';
import { generateID } from 'utils/common';
import { EditButton } from '../template-stage-edit-buttons.styled-components';
import {
	InputSlotCard,
	InputSlotImage,
} from '../template-stage-edit-slots.styled-components';
import { AttachmentSlotForm } from './AttachmentSlotForm';
import { AttachmentSlotContainer } from './template-stage-card.styled-components';

type Props = {
	stage: Stage;
};

export const EditAttachmentSlots = ({ stage }: Props) => {
	const { template, saveTemplate } = useTemplateContext();
	const { info } = useContext(NotificationsContext);
	const [attachmentToEdit, setAttachmentToEdit] = useState<InputSlot>();
	const [addingSlot, setAddingSlot] = useState(false);

	const deleteSlot = async (inputSlot: InputSlot) => {
		if (!stage) return;
		if (!stage.inputSlots) return;

		const updatedStage = {
			...stage,
			inputSlots: stage.inputSlots.filter((slot) => slot._id !== inputSlot._id),
		};

		const updatedTemplate = {
			...(template as WorkflowTemplate),
			stages: template?.stages.map((stage) => {
				if (stage._id === updatedStage?._id) return updatedStage;

				if (
					stage?.substages &&
					stage.substages?.length &&
					flattenDeep(stage?.substages).some(
						(substage) => substage._id === updatedStage?._id
					)
				) {
					return {
						...stage,
						substages: stage.substages.map((substage) => {
							return substage.map((substageGroup) =>
								substageGroup._id === updatedStage?._id
									? updatedStage
									: substageGroup
							);
						}),
					};
				}

				return stage;
			}) as Stage[],
			sideTasks: template?.sideTasks?.map((sT) => {
				return sT._id === stage._id ? updatedStage : sT;
			}) as Stage[],
		};
		await saveTemplate(updatedTemplate);
		info('Attachment Slot Deleted');
	};

	const editAttachmentSlot = async (attachmentSlot: InputSlot) => {
		if (!attachmentSlot.label) return;

		const updatedStage = {
			...stage,
			inputSlots: stage.inputSlots
				? [
						...stage.inputSlots.filter(
							(slot) => slot._id !== attachmentSlot._id
						),
						attachmentSlot,
				  ]
				: [attachmentSlot],
		};

		const updatedTemplate = {
			...(template as WorkflowTemplate),
			stages: template?.stages.map((stage) => {
				if (stage._id === updatedStage?._id) return updatedStage;

				if (
					stage?.substages &&
					stage.substages?.length &&
					flattenDeep(stage?.substages).some(
						(substage) => substage._id === updatedStage?._id
					)
				) {
					return {
						...stage,
						substages: stage.substages.map((substage) => {
							return substage.map((substageGroup) =>
								substageGroup._id === updatedStage?._id
									? updatedStage
									: substageGroup
							);
						}),
					};
				}

				return stage;
			}) as Stage[],
			sideTasks: template?.sideTasks?.map((sT) => {
				return sT._id === stage._id ? updatedStage : sT;
			}) as Stage[],
		};
		await saveTemplate(updatedTemplate);
		info('Attachment slot updated');
		setAttachmentToEdit(undefined);
	};

	const addAttachmentSlot = async (attachmentSlot: InputSlot) => {
		if (!attachmentSlot.label) return;

		setAddingSlot(false);
		const freshSlot = { ...attachmentSlot, _id: generateID() };

		const updatedStage = {
			...stage,
			inputSlots: stage.inputSlots
				? [...stage.inputSlots, freshSlot]
				: [freshSlot],
		};

		const updatedTemplate = {
			...(template as WorkflowTemplate),
			stages: template?.stages.map((stage) => {
				if (stage._id === updatedStage?._id) return updatedStage;

				if (
					stage?.substages &&
					stage.substages?.length &&
					flattenDeep(stage?.substages).some(
						(substage) => substage._id === updatedStage?._id
					)
				) {
					return {
						...stage,
						substages: stage.substages.map((substage) => {
							return substage.map((substageGroup) =>
								substageGroup._id === updatedStage?._id
									? updatedStage
									: substageGroup
							);
						}),
					};
				}

				return stage;
			}) as Stage[],
			sideTasks: template?.sideTasks?.map((sT) => {
				return sT._id === stage._id ? updatedStage : sT;
			}) as Stage[],
		};
		await saveTemplate(updatedTemplate);
		info('Attachment slot added');
	};

	if (!stage.inputSlots) return null;
	return (
		<AttachmentSlotContainer>
			{!addingSlot && (
				<>
					<div className="input-slots">
						{/* <h2>Current Attachment Slots</h2> */}
						{stage.inputSlots.map((inputSlot) => (
							<InputSlotCard key={inputSlot._id}>
								<InputSlotImage>
									<FontAwesomeIcon icon={faFileUpload} />
								</InputSlotImage>
								<CardBody className="d-flex justify-content-between p-3">
									<p className="mb-0 align-self-center mr-2">
										{inputSlot.label}
									</p>
									<div className="d-flex">
										<EditButton
											size="sm"
											onClick={() => setAttachmentToEdit(inputSlot)}
											className="btn-light mr-3"
										>
											<FontAwesomeIcon icon={faEdit} />
										</EditButton>
										<AnchorSpan
											className="p-1"
											aria-label="Delete input slot"
											title="Delete input slot"
											onClick={async () => {
												const { isConfirmed } = await RomeSwal.fire({
													icon: 'question',
													title: 'Are you sure?',
													text: 'Deleting the input slot cannot be reversed.',
													confirmButtonText: 'Delete',
													showCancelButton: true,
													cancelButtonText: 'Cancel',
												});
												if (isConfirmed) await deleteSlot(inputSlot);
											}}
										>
											<FontAwesomeIcon icon={faTimes} />
										</AnchorSpan>
									</div>
								</CardBody>
							</InputSlotCard>
						))}

						<SecondaryButton
							style={{ maxWidth: '200px' }}
							onClick={() => setAddingSlot(true)}
						>
							Add New
						</SecondaryButton>
					</div>

					{attachmentToEdit && (
						<div className="edit-input-slot">
							{/* <h2>Edit Attachment</h2> */}
							<AttachmentSlotForm
								onCancel={() => setAttachmentToEdit(undefined)}
								attachmentSlot={attachmentToEdit}
								onSubmit={editAttachmentSlot}
							/>
						</div>
					)}
				</>
			)}

			{addingSlot && (
				<Container>
					<h2>Add Attachment</h2>
					<Row>
						<AttachmentSlotForm
							onCancel={() => setAddingSlot(false)}
							onSubmit={addAttachmentSlot}
						/>
					</Row>
				</Container>
			)}
		</AttachmentSlotContainer>
	);
};
