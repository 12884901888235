import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import { useAssetHelper } from './components/helpers/useAssetHelper';
import LoadingIndicator from 'core-ui/components/LoadingIndicator';
import { AssetVersion } from '../workflow/workflows/types/workflow.types';
import { FullWidthButton } from './components/asset-details-sidebar/asset-details-tab-set.styled-components';
import styled from 'styled-components';

const DownloadButton = styled<any>(FullWidthButton)`
	white-space: nowrap;
	padding: 0.5rem;
	width: 170px;
`;

const AssetTest = () => {
	const [Assets, setAssets] = useState<AssetVersion[]>([]);
	const { runAssetTest } = useAssetHelper();

	useEffect(() => {
		runAssetTest().then((e) => setAssets(e.data));
	}, [Assets, runAssetTest]);

	const downloadFile = async () => {
		const arr = [];
		for (const asset of Assets) {
			arr.push(asset.fileName + '\n');
		}

		const url = window.URL.createObjectURL(new Blob(arr));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', 'missing-assets'); //or any other extension
		document.body.appendChild(link);
		link.click();
		link.remove();
		return true;
	};

	return (
		<Container>
			{!Assets?.length && <LoadingIndicator />}
			<div>
				<h1>Asset Tester</h1>
				<p hidden={Assets?.length === 0}>
					DAM assets listed in database, but asset file not found in storage:{' '}
					{Assets.length}
				</p>
				<DownloadButton hidden={Assets?.length === 0} onClick={downloadFile}>
					Download Filename List
				</DownloadButton>
				<hr />

				<div>
					<ol>
						{Assets?.map((asset) => (
							<li>
								Storage Path:{' '}
								<a href={asset._id} target="_asset">
									{asset.path}
								</a>
							</li>
						))}
					</ol>
				</div>
			</div>
		</Container>
	);
};

export default AssetTest;
