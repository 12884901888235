import { UploadResult, UppyFile } from '@uppy/core';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import { Dashboard } from '@uppy/react';
import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { authProvider } from 'utils/core';
import { MultipleFileUploadProvider } from 'utils/core/MultipleFileUploadProvider';
import { generateID, noop, requiredValue } from '../../../utils/common';
import { _logError } from '../../../utils/common/log';
import {
	AssetVersion,
	EntityMetadataTemplate,
} from '../../workflow/workflows/types/workflow.types';

const multipleFileUploadProvider = new MultipleFileUploadProvider(
	requiredValue(process.env.REACT_APP_ROME_API_ENDPOINT),
	authProvider
);
interface AssetPickerProps {
	files: UppyFile[];
	onSelect?: (result: UploadResult) => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function assetFromUppyFile(file: UppyFile): AssetVersion {
	return {
		_id: '$temp_' + generateID(),
		fileName: file.name,
		type: file.extension,
		fileSizeBytes: file.size,
		path: '',
	} as AssetVersion;
}

const MultipleAssetPicker = ({ onSelect = noop, files }: AssetPickerProps) => {
	const uploadProvider = multipleFileUploadProvider;
	const { uppy } = uploadProvider;
	const [currFiles, setFiles] = React.useState(files);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [numFiles, setNumFiles] = useState(files?.length || 0);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [templateUsed, setTemplateUsed] = useState<EntityMetadataTemplate>(
		{} as EntityMetadataTemplate
	);

	useEffect(() => {
		uppy.on('complete', (file) => {
			onSelect(file);
			const files = file.successful as UppyFile[];
			setFiles(files);
		});

		return () => {
			uppy.off('complete', onSelect);
			uppy.reset();
		};
	}, [uppy, onSelect]);

	/* eslint-disable */

	useEffect(() => {
		if (!isEqual(files, currFiles)) {
			setFiles(files);
		}
	}, [files]);

	// clear only works when given
	// an empty dependency array
	const clearInputSlot = useCallback((uf: UppyFile) => {
		try {
			uppy.removeFile(uf.id);
			const newUploads = uppy.getFiles();
			setFiles(newUploads);
			setNumFiles(newUploads.length);
		} catch (error) {
			_logError(error);
		}
	}, []);
	/* eslint-enable */
	return (
		<>
			<Dashboard
				uppy={uppy}
				hideUploadButton={true}
				fileManagerSelectionType="both"
				proudlyDisplayPoweredByUppy={false}
			/>
		</>
	);
};

export default MultipleAssetPicker;
