import { SiteDefaults } from 'context/useThemeContext';
import storageModel from 'utils/models/StorageModel';
import { Container } from 'reactstrap';
import styled from 'styled-components';
const defaults = (JSON.parse(storageModel.get('SITE_DEFAULTS') as string) ||
	{}) as SiteDefaults;

export const PaginationWrapper = styled(Container)`
	.wrapper {
		padding: 2rem 0;
		display: flex;
		justify-content: center;
	}
	.separator {
		width: 1rem;
		margin: 0 0.25rem;
	}
	.page-item {
		white-space: nowrap;
		background: transparent;
		border: none;
		height: 2rem;
		width: 2rem;
		margin: 0 0.25rem;
		border-radius: 50%;
		font-weight: 600;
		color: ${defaults?.secondary};
		&:hover {
			text-decoration: underline;
		}
		&:focus {
			outline: 0;
		}
	}
	.active {
		background: ${defaults?.secondary};
		border-color: ${defaults?.secondary};
		color: #fff;
	}
	.sides {
		box-shadow: transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px,
			rgba(0, 0, 0, 0.18) 0px 2px 4px;
		&:hover {
			text-decoration: none;
			box-shadow: transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px,
				rgba(0, 0, 0, 0.12) 0px 6px 16px;
		}
	}
`;
// pagination.module.scss
// Colour variables used: $primary, $white
