import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import {
	Workflow,
	WorkflowTemplate,
} from 'components/workflow/workflows/types';
import { WorkflowsIndexView } from 'components/workflow/workflows/WorkflowIndexPage';
import { WorkflowCard, WorkflowsTable } from '../components';
import { useAuthContext } from '../../../../utils/auth';
import { useGroupContext } from '../../../../utils/auth';
import { useWorkflowContext } from 'context/useWorkflowStore';
import * as H from '../helpers';
import { useMatch } from '@reach/router';
import { orderBy, uniqBy } from 'lodash';
import { usePageHistory } from 'context/PageHistoryContext';
import { useAxios } from 'hooks';

export const AllWorkflows = () => {
	const pageHistory = usePageHistory();
	const { entities } = useWorkflowContext();
	const { groupsForCurrentUser } = useGroupContext();
	const { currentUser } = useAuthContext();
	const [sort, setSort] = useState(
		pageHistory.getStateForKey(window.location.href, 'sort') ?? 'A - Z'
	);

	const templateStore = useAxios<WorkflowTemplate>('templates');
	const [templates, setTemplates] = useState<WorkflowTemplate[]>();
	const [workflows, setWorkflows] = useState<Workflow[]>([]);
	const match = useMatch('/admin/workflow/workflows/status/:status');
	const [ran, setRan] = useState(false);
	const [defaultFilter, setDefaultFilter] = useState(
		match
			? [match.status]
			: Array.isArray(
					pageHistory.getStateForKey(window.location.href, 'defaultFilter')
			  )
			? [...pageHistory.getStateForKey(window.location.href, 'defaultFilter')]
			: pageHistory.getStateForKey(window.location.href, 'defaultFilter') ??
			  'all'
	);

	useEffect(() => {
		if (!defaultFilter.length && !window.location.hash) {
			setDefaultFilter(['all']);
			handleFilter(['all']);
		}
		//eslint-disable-next-line
	}, [defaultFilter]);

	const options = React.useMemo(() => {
		if (templates) {
			return orderBy(
				templates
					.filter((t) => t.editingState === 'final')
					.map((template) => ({ label: template.title, value: template._id })),
				(a) => a.label,
				'asc'
			);
		}
		return [];
	}, [templates]);

	useEffect(() => {
		templateStore.findAll().then(setTemplates);
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (!entities) return;
		if (!!ran) return;
		handleFilter(defaultFilter);
		setRan(true);
		//eslint-disable-next-line
	}, [entities, currentUser, workflows]);

	useEffect(() => {
		const page = {
			path: window.location.href,
			state: [
				{
					key: 'defaultFilter',
					value: defaultFilter,
				},
			],
		};

		pageHistory.updateState(page, 'update');

		if (!defaultFilter.length && !window.location.hash) {
			setDefaultFilter(['all']);
			handleFilter('all');
		}
		//eslint-disable-next-line
	}, [defaultFilter]);

	useEffect(() => {
		const page = {
			path: window.location.href,
			state: [
				{
					key: 'sort',
					value: sort,
				},
			],
		};

		pageHistory.updateState(page, 'update');
		//eslint-disable-next-line
	}, [sort]);

	// handleSorting
	const sortingOptions = [
		'A - Z',
		'Z - A',
		'Due Date (Newest - Oldest)',
		'Due Date (Oldest - Newest)',
	].map((val) => ({ label: val, value: val }));
	// Sort Handler
	const handleSort = (sorter: string) => {
		setSort(sorter);
		if (sorter === 'A - Z') setWorkflows(H.alphabetizeBy('title', workflows));
		if (sorter === 'Z - A') setWorkflows(H.reverseAlphabetizeBy('title'));
		if (sorter === 'Due Date (Newest - Oldest)')
			setWorkflows(R.sort(H.sortByDueDateAsc));
		if (sorter === 'Due Date (Oldest - Newest)')
			setWorkflows(R.sort(H.sortByDueDateDesc));
	};

	const getSorterFn = (wfs: Workflow[]) => {
		if (sort === 'A - Z') return () => orderBy(wfs, (a) => a.title, 'asc');
		if (sort === 'Z - A') return () => orderBy(wfs, (a) => a.title, 'desc');
		if (sort === 'Due Date (Newest - Oldest)')
			return () => R.sort(H.sortByDueDateAsc);
		if (sort === 'Due Date (Oldest - Newest)')
			return () => R.sort(H.sortByDueDateDesc);

		return () => [];
	};

	// filterOptions
	const filterOptions = [
		['All Workflows', 'all'],
		['Pipeline', 'pipeline'],
		['Active', 'active'],
		['Paused', 'paused'],
		['Cancelled', 'cancelled'],
		['Completed', 'completed'],
		['Healthy', 'healthy'],
		['Overdue', 'overdue'],
		['Roadblocked', 'roadblocked'],
		['Created by me', 'createdBy'],
		['Collaborating', 'collaborating'],
		['Following', 'following'],
	].map(([label, value]) => ({ label, value }));
	// handle Filtering
	const handleFilter = (value: string | string[]) => {
		if (value) {
			if (Array.isArray(value)) {
				const newFilter = value;
				const newFlows: Workflow[] = [];

				setDefaultFilter(newFilter);
				newFilter.forEach((filterOption) => {
					newFlows.push(
						...H.filterWorkflows(
							currentUser,
							groupsForCurrentUser,
							entities,
							filterOption
						)
					);
				});
				const uniqueFlows = uniqBy(newFlows, (a) => a._id);
				setWorkflows(getSorterFn(uniqueFlows)());
			} else {
				const newFilter = value;
				const filteredFlows = H.filterWorkflows(
					currentUser,
					groupsForCurrentUser,
					entities,
					newFilter
				);
				setWorkflows(getSorterFn(filteredFlows)());
			}
		} else {
			setWorkflows([]);
			setDefaultFilter([]);
		}
	};
	const views = {
		cards: (workflows: Workflow[]) =>
			workflows.map((workflow) => (
				<WorkflowCard key={workflow?._id} workflow={workflow} />
			)),
		table: (workflows: Workflow[]) => <WorkflowsTable workflows={workflows} />,
	};
	const sorting = {
		options: sortingOptions,
		fn: handleSort,
	};
	const filters = {
		options: filterOptions,
		fn: handleFilter,
	};

	return (
		<WorkflowsIndexView
			template={{
				options,
				fn: (value: string | string[]) => {
					if (Array.isArray(value) && value.length === 0)
						setWorkflows(getSorterFn(entities)());
					else if (value) {
						setWorkflows(
							getSorterFn(
								Array.isArray(value)
									? workflows.filter((wf) =>
											value.includes(
												(wf?.templateUsed as WorkflowTemplate)?._id
											)
									  )
									: workflows.filter(
											(wf) =>
												(wf?.templateUsed as WorkflowTemplate)?._id === value
									  )
							)()
						);
					} else setWorkflows(getSorterFn(entities)());
				},
			}}
			defaultFilter={defaultFilter}
			defaultSort={sort}
			title="Workflows"
			collection={workflows}
			sorting={sorting}
			filters={filters}
			views={views}
			saveQuery
			create
		/>
	);
};
