import { SiteDefaults } from 'context/useThemeContext';
import LoadingIndicator from 'core-ui/components/LoadingIndicator';
import React from 'react';
import styled from 'styled-components';
import storageModel from 'utils/models/StorageModel';
import themeStore from '../core-ui/models/ThemeStore';

const { colors } = themeStore.selectedTheme;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const defaults = (JSON.parse(storageModel.get('SITE_DEFAULTS') as string) ||
	{}) as SiteDefaults;

// styled components
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LoadingWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	height: 100vh;

	@keyframes run {
		0% {
			color: ${colors.lightGrey};
			left: -90px;
		}
		50% {
			color: ${colors.darkestGrey};
		}
		100% {
			color: ${colors.lightGrey};
			left: 90px;
		}
	}

	@-webkit-keyframes bounce {
		0%,
		80%,
		100% {
			-webkit-transform: scale(0);
		}
		40% {
			-webkit-transform: scale(1);
		}
	}

	@keyframes bounce {
		0%,
		80%,
		100% {
			-webkit-transform: scale(0);
			transform: scale(0);
		}
		40% {
			-webkit-transform: scale(1);
			transform: scale(1);
		}
	}
`;

// interfaces
interface Props {
	label?: string;
	alignItems?: string;
	justifyContent?: string;
}

// components
export const Loading = (props: Props) => <LoadingIndicator />;

export const LoadingRow = (props: Props) => {
	// const label = props.label ? props.label : 'Loading...';

	return <LoadingIndicator />;
};

export default Loading;
