import { Redirect } from '@reach/router';
import { CreateATicketFab } from 'components/CreateATicketFab';
import DialogPortal from 'components/modals/dialog-portal.component';
import { ViewWrapper } from 'components/ui';
import moment from 'moment';
import React, { useEffect } from 'react';
import { saveNode } from 'utils/common';
import { AuthContext } from 'utils/core';
import {
	SessionTimeout,
	SessionTimeoutService,
} from 'utils/core/SessionTimeoutService';
import { useModalCreator } from 'utils/ModalStack';
import { appRoutes } from './app.routes';

const ConfirmationDialog = React.lazy(() =>
	import('../components/modals/confirmation-dialog.component')
);
const AdminNavigation = React.lazy(() =>
	import('../components/admin-navigation/admin-navigation.component')
);

const Footer = React.lazy(() => import('../components/footer.component'));

const MainView = () => {
	// const permissions = React.useContext(RoleContext);

	return (
		<ViewWrapper>
			{<DialogPortal />}
			<CreateATicketFab />
			<div className="navwrapper">
				<AdminNavigation editThemeView={false} />
			</div>
			{/* 
			{!!permissions.roles.length &&
				permissions.roles.length > 0 &&
				permissions.showDisabledOverlay() === false && (
					<div className="w-25 m-auto d-block py-3 h-100">
						<DisabledMask style={{ top: '200px' }} />
						<Alert
							className="position-fixed"
							style={{ top: '50%', zIndex: 9 }}
							fade
							title="This feature is not enabled"
							color={'info'}
						>
							<div className="p-4 align-items-center d-flex justify-content-center">
								<p>
									<FontAwesomeIcon icon={faInfoCircle} /> Contact your account
									representative to enable this feature.
								</p>
							</div>
						</Alert>
					</div>
				)}

 */}
			{appRoutes}
			<Footer />
			<SessionTimeout />
		</ViewWrapper>
	);
};

const Admin = () => {
	const auth = React.useContext(AuthContext);

	const storedAuthValue = JSON.parse(
		localStorage.getItem('rome_auth') as string
	);

	const isOverdue =
		!storedAuthValue?.expiresAt ||
		storedAuthValue?.expiresAt === -1 ||
		moment(storedAuthValue?.expiresAt).isBefore(moment(new Date()));

	const isSessionAboutToEnd = React.useMemo(
		() => moment(storedAuthValue?.expiresAt).isBefore(moment(new Date())),
		[storedAuthValue]
	);
	const sessionTimeoutSvc = React.useMemo(
		() => new SessionTimeoutService(auth),
		[auth]
	);
	const modalCreator = useModalCreator();

	useEffect(() => {
		if (sessionTimeoutSvc.isIdle || isSessionAboutToEnd) {
			modalCreator.addModal(
				<ConfirmationDialog
					header="Session is about to expire"
					onConfirm={() => sessionTimeoutSvc.hideModal()}
				>
					<p>Your session is about to expire. Continue?</p>
					<span>{sessionTimeoutSvc.logoutCountdown}</span>
				</ConfirmationDialog>
			);
		}
	}, [sessionTimeoutSvc, isSessionAboutToEnd, modalCreator]);

	if (!storedAuthValue || isOverdue) {
		saveNode('rome_auth', {
			accessToken: null,
			expiresAt: -1,
			idToken: null,
			authUser: null,
		});
		return <Redirect to="/auth" noThrow />;
	}

	const Skeleton = <MainView />;

	return <>{Skeleton}</>;
};

export default Admin;
