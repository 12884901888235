import { navigate, RouteComponentProps, useParams } from '@reach/router';
import { useFetching } from 'hooks/useFetching';
import { NotificationsContext } from 'components/notifications';
import React from 'react';
import { Container } from 'reactstrap';
import { AssetVersion } from '../workflow/workflows/types/workflow.types';
import CommonAssetVersionDisplay from './components/common-asset-version-display.component';
import { useAssetHelper } from './components/helpers/useAssetHelper';
import LoadingAssetState from './components/loading-asset-state.component';

function assetReducer(
	state: { asset: AssetVersion | null; version: AssetVersion | null },
	action: {
		type: 'set';
		payload: { asset: AssetVersion; version: AssetVersion | null };
	}
) {
	switch (action.type) {
		case 'set':
			return {
				...state,
				asset: action.payload.asset,
				version: action.payload.version,
			};
		default:
			return state;
	}
}

const AssetDetailsView = (props: RouteComponentProps<{ assetId?: string }>) => {
	const { findOne: findAsset, findVersion } = useAssetHelper();
	const { isAssetVersion } = useParams();
	const [state, dispatch] = React.useReducer(assetReducer, {
		asset: null,
		version: null,
	});
	const { isFetching, beginFetching, finishFetching } = useFetching();
	const { info } = React.useContext(NotificationsContext);
	React.useEffect(() => {
		console.log(state.asset, isAssetVersion);

		if (isFetching) return;
		if (!state.asset && props.assetId && !isAssetVersion) {
			beginFetching();
			findAsset(props.assetId)
				.then((asset) => {
					dispatch({
						type: 'set',
						payload: { asset: asset as AssetVersion, version: null },
					});
				})
				.finally(finishFetching);
		} else if (!state.asset && props.assetId && isAssetVersion) {
			beginFetching();
			findVersion(props.assetId)
				.then((findVersionResponse) => {
					dispatch({
						type: 'set',
						payload: {
							asset: findVersionResponse?.asset as AssetVersion,
							version: findVersionResponse?.version as AssetVersion,
						},
					});
				})
				.finally(finishFetching);
		}
		//eslint-disable-next-line
	}, [
		state.asset,
		findAsset,
		props.assetId,
		isFetching,
		finishFetching,
		beginFetching,
	]);
	console.log(state);
	const render = () => {
		const asset = state.asset;
		const version = state.version;
		if (asset) {
			if (!version) {
				return (
					<CommonAssetVersionDisplay
						isAssetVersion={isAssetVersion}
						setAsset={(asset) =>
							dispatch({
								payload: { asset: asset, version: state.version },
								type: 'set',
							})
						}
						onDelete={() => {
							const infoCb = () => info('Successfully deleted asset.');
							navigate('/admin/dam/assets').then(infoCb);
						}}
						version={state.version as AssetVersion}
						damAsset={state.asset as AssetVersion}
					/>
				);
			}
			if (asset?.versionId) {
				return (
					<CommonAssetVersionDisplay
						isAssetVersion={isAssetVersion}
						setAsset={(asset) =>
							dispatch({
								payload: { asset: asset, version: state.version },
								type: 'set',
							})
						}
						onDelete={() => {
							const infoCb = () => info('Successfully deleted asset.');
							navigate('/admin/dam/assets').then(infoCb);
						}}
						version={state.version as AssetVersion}
						damAsset={state.asset as AssetVersion}
					/>
				);
			}
		}

		return <LoadingAssetState />;
	};

	return <Container>{render()}</Container>;
};

export default AssetDetailsView;
