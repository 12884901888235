import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { EntityMetadata, InputSlot } from 'components/workflow/workflows/types';
import { Col, Row, Container, Input, Label } from 'reactstrap';
import { FormSubmitButton } from 'components/forms/FormSubmitButton';
import { SecondaryButton } from 'components/buttons.styled-components';
import TagInput from 'components/tag-input/TagInput';

type Props = {
	attachmentSlot?: InputSlot;
	onSubmit: (attachmentSlot: InputSlot) => void;
	onCancel: () => void;
};

export const AttachmentSlotForm = (props: Props) => {
	const form = useForm();
	const [tags, setTags] = useState<string[]>([]);
	const [label, setLabel] = useState(props.attachmentSlot?.label ?? '');
	type FormData = {
		label: string;
		tags: string[];
		uploadToDAM: boolean;
		optional: boolean;
	};
	const onSubmit = (formData: FormData) => {
		props.onSubmit({
			...props.attachmentSlot,
			label: label,
			uploadToDAM: uploadToDam,
			optional: optional,
			metadata: {
				fields: [],
				fieldOptions: {},
				fieldTypes: [],
				tags: tags,
				values: {},
			},
		} as InputSlot);
	};

	const [optional, setOptional] = useState(props?.attachmentSlot?.optional);

	const [uploadToDam, setUploadToDam] = useState(
		props?.attachmentSlot?.uploadToDAM ?? false
	);
	console.log(props.attachmentSlot);

	return (
		<Container>
			<form onSubmit={form.handleSubmit(onSubmit)}>
				<Row>
					<Col md={6}>
						<label className="d-block w-100">Input Slot Label</label>
						<Input
							name="label"
							type="text"
							form={form as any}
							validators={{ required: false }}
							onChange={(e) => setLabel(e.target.value)}
							defaultValue={label ?? 'Unlabeled Input Slot'}
						/>
					</Col>
					<Col md={6}>
						<label className="d-block w-100">Upload to DAM</label>
						<select
							className="form-control"
							placeholder={'Upload slot assets to DAM'}
							value={uploadToDam.toString()}
							onChange={(e) => setUploadToDam(e.target.value === 'true')}
							name="uploadToDAM"
						>
							<option value="true">True</option>
							<option value="false">False</option>
						</select>
					</Col>
				</Row>
				<Row>
					<Col md={12} className={'ml-3'}>
						<Label check>
							<Input
								onChange={(e) => setOptional(e.target.checked)}
								type={'checkbox'}
								name={'optional'}
								defaultChecked={optional}
							/>
							Optional?
						</Label>
					</Col>
					<Col md={12}>
						<TagInput
							metadata={props.attachmentSlot?.metadata as EntityMetadata}
							onChange={(updated) => setTags(updated?.tags as string[])}
						/>
					</Col>
				</Row>
				<Row className="my-3 d-flex align-items-end">
					<Col md={12}>
						<FormSubmitButton>Submit</FormSubmitButton>
						<SecondaryButton
							type="button"
							className="ml-2"
							onClick={props.onCancel}
						>
							Cancel
						</SecondaryButton>
					</Col>
				</Row>
			</form>
		</Container>
	);
};
