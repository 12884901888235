import { css } from 'styled-components';

import themeStore from '../models/ThemeStore';

import { boxShadowFocus } from '../ui/ui';

const { colors } = themeStore.selectedTheme;

const circularButton = css`
	&& {
		background-color: transparent;
		border-radius: 10em;
		border: none;
	}

	height: 28px;
	width: 28px;

	svg {
		color: ${colors.darkerGrey};
	}

	&:hover {
		&& {
			background-color: ${colors.darkerGrey};
		}

		svg {
			color: var(--white);
		}
	}

	&:focus {
		${boxShadowFocus};
	}
`;
export default circularButton;
