import { Stage } from 'components/workflow/workflows/types';
import { useWorkflowContext } from 'context/useWorkflowStore';
import { orderBy, uniqBy } from 'lodash';
import moment from 'moment';
import { default as React, useEffect, useState } from 'react';
import { useAuthContext, useGroupContext } from '../../../../utils/auth';
import { MyAssignmentsTable } from '../components';
import * as H from '../helpers';
import { getStageDueDate } from '../helpers';
import { WorkflowsIndexView } from '../WorkflowIndexPage';

/**
 * Creates table of assignments for current user
 * @returns WorkflowsIndexView
 */
export const MyAssignments = () => {
	const { entities } = useWorkflowContext();
	const { groupsForCurrentUser } = useGroupContext();
	const { currentUser } = useAuthContext();
	const [sort, setSort] = useState<string>('A - Z');
	const [stages, setStages] = useState<Stage[]>([]);
	const [defaultFilter, setDefaultFilter] = useState<string[]>([]);
	const [isHashed, setIsHashed] = useState(false);

	// ? Get organization flag from env to perform some conditional logic on getUserStages?
	// TODO We should figure out what it's doing and move this logic to some maintainable lookup table or so
	const ORG = process.env.REACT_APP_ORG;
	const assignedDirectlyOnly = !!ORG && ORG === 'PDL';

	const getSortProperty = (stage: Stage) => {
		if (sort.includes('ASC') || sort.includes('DESC'))
			return new Date(getStageDueDate(stage));
		return stage.title;
	};

	const userStages = React.useMemo(
		() =>
			!entities
				? []
				: H.getUsersStages(
						entities?.filter((status: any) => status !== 'cancelled'),
						currentUser,
						groupsForCurrentUser,
						assignedDirectlyOnly
				  ),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[entities, ORG]
	);

	/**
	 * This useEffect just dumps stages found and checks for duplicate stage ids
	 */
	useEffect(() => {
		const stageCheck = stages
			.map((stage: Stage) => {
				return {
					id: stage._id,
					title: stage.title,
					status: stage.status,
					wf: stage.workflowTitle,
				};
			})
			.sort((a, b) => (a.id > b.id ? 1 : -1));

		let dups = [];
		for (let i = 0; i < stageCheck.length - 1; i++) {
			if (stageCheck[i + 1].id === stageCheck[i].id) {
				dups.push(stageCheck[i]);
			}
		}
	}, [stages]);

	useEffect(() => {
		if (!entities) return;
		if (entities?.length && !isHashed && !window.location.hash) {
			setDefaultFilter(['all']);
			setStages(
				orderBy(
					userStages,
					(s) => getSortProperty(s),
					sort.includes('ASC') || sort === 'A - Z' ? 'asc' : 'desc'
				)
			);
		}
		//eslint-disable-next-line
	}, [entities, userStages]);

	useEffect(() => {
		if (window.location.hash) {
			setIsHashed(true);
			const filter = window.location.hash.replace('#', '');
			window.location.hash = '';

			handleFilter([filter]);
		}
		//eslint-disable-next-line
	}, [defaultFilter]);

	// handleSorting
	const sortingOptions = [
		'A - Z',
		'Z - A',
		'Due Date (ASC)',
		'Due Date (DESC)',
	].map((val) => ({
		label: val,
		value: val,
	}));

	/**
	 * Sorting handler passed to WorkflowsIndexView
	 * @param sorter
	 */
	// ! This sorting handler will be replaced with material ui table functionality
	const handleSort = (sorter: string) => {
		setSort(sorter);
		if (sorter === 'A - Z') setStages(H.alphabetizeBy('title', stages));
		if (sorter === 'Z - A') setStages(H.reverseAlphabetizeBy('title'));
		if (sorter === 'Due Date (ASC)')
			setStages((stages) =>
				orderBy(
					stages,
					(stage) =>
						getStageDueDate(stage) ? new Date(getStageDueDate(stage)) : 0,
					'asc'
				)
			);
		if (sorter === 'Due Date (DESC)')
			setStages((stages) =>
				orderBy(stages, (stage) => new Date(getStageDueDate(stage)), 'desc')
			);
	};

	// filterOptions
	const filterOptions = [
		currentUser?.proxyingFor?._id ? ['Proxied Assignments', 'proxied'] : [],
		['All Assignments', 'all'],
		['Active', 'active'],
		['Pipeline', 'pipeline'],
		['Completed', 'completed'],
		['Overdue', 'overdue'],
		['Roadblocked', 'roadblocked'],
	]
		.filter(([label, value]) => !!label && !!value)
		.map(([label, value]) => ({ label, value }));

	/**
	 * Handle filtering. Passed to WorkflowsIndexView
	 * @param filter
	 */
	const handleFilter = (filter: string | string[]) => {
		if (filter) {
			const allFilteredStages: Stage[] = [];
			setDefaultFilter(Array.isArray(filter) ? filter : [filter]);
			if (Array.isArray(filter)) {
				filter.forEach((filters) => {
					if (filters === 'overdue')
						allFilteredStages.push(
							...(userStages.filter(
								(stage: Stage) =>
									!!stage &&
									stage?.status === 'active' &&
									moment(getStageDueDate(stage)).isBefore(new Date())
							) || [])
						);
					if (filters === 'all') allFilteredStages.push(...userStages);
					if (filters === 'active')
						allFilteredStages.push(
							...userStages.filter(({ status }: Stage) => status === 'active')
						);
					if (filters === 'completed')
						allFilteredStages.push(
							...userStages.filter(
								({ status }: Stage) => status === 'completed'
							)
						);
					if (filters === 'pipeline')
						allFilteredStages.push(
							...userStages.filter(({ status }: Stage) => status === 'queue')
						);
					if (filters === 'roadblocked')
						allFilteredStages.push(
							...userStages.filter(
								({ status }: Stage) => status === 'roadblocked'
							)
						);
					if (filters === 'proxied')
						allFilteredStages.push(
							...userStages.filter(({ owners }: Stage) =>
								owners.some(
									(owner) => owner._id === currentUser?.proxyingFor?._id
								)
							)
						);
				});
				setStages(uniqBy(allFilteredStages, (stage) => stage._id));
			} else {
				if (filter === 'overdue')
					allFilteredStages.push(
						...(userStages.filter(
							(stage: Stage) =>
								!!stage &&
								stage?.status === 'active' &&
								moment(getStageDueDate(stage)).isBefore(new Date())
						) || [])
					);
				if (filter === 'all') allFilteredStages.push(...userStages);
				if (filter === 'active')
					allFilteredStages.push(
						...userStages.filter(({ status }: Stage) => status === 'active')
					);
				if (filter === 'completed')
					allFilteredStages.push(
						...userStages.filter(({ status }: Stage) => status === 'completed')
					);
				if (filter === 'pipeline')
					allFilteredStages.push(
						...userStages.filter(({ status }: Stage) => status === 'queue')
					);
				if (filter === 'roadblocked')
					allFilteredStages.push(
						...userStages.filter(
							({ status }: Stage) => status === 'roadblocked'
						)
					);
				if (filter === 'proxied')
					allFilteredStages.push(
						...userStages.filter(({ owners }: Stage) =>
							owners.some(
								(owner) => owner._id === currentUser?.proxyingFor?._id
							)
						)
					);
				setStages(uniqBy(allFilteredStages, (stage) => stage._id));
			}
		} else {
			setStages([]);
			setDefaultFilter([]);
		}
	};

	// * These are passed to the WorkflowsIndexView.
	// * Don't need them if not providing dynamic sorting and filtering
	// * Basically, we're bothering with the complex view output because it paginates
	const views = {
		table: (stages: Stage[]) => <MyAssignmentsTable stages={stages} />,
	};
	const sorting = {
		options: sortingOptions,
		fn: handleSort,
	};
	const filters = {
		options: filterOptions,
		fn: handleFilter,
	};

	const order = [
		'roadblock',
		'overdue',
		'active',
		'pipeline',
		'queue',
		'completed',
	];

	stages.sort(function (a, b) {
		// ? MOVE THIS UTIL FUNCTION TO A LIBRARY? Have to rework getDueDate function to not return mixed types
		const dueDateA = new Date(getStageDueDate(a)).getTime();
		const dueDateB = new Date(getStageDueDate(b)).getTime();

		return (
			order.indexOf(a.status as string) - order.indexOf(b.status as string) ||
			dueDateA - dueDateB
		);
	});

	return (
		<WorkflowsIndexView
			defaultFilter={defaultFilter}
			defaultSort={sort}
			title="My Assignments"
			collection={stages}
			// ! passing functions here
			sorting={sorting}
			filters={filters}
			views={views}
			searchTarget="workflow"
		/>
	);
};
