import { flattenStages } from 'components/workflow/workflows/helpers';
import { Flattenable, Stage } from 'components/workflow/workflows/types';
import { useTemplateContext } from 'context/useTemplateContext';
import React, { useState } from 'react';
import LineTo from 'react-lineto';
import WindowSizeListener from 'react-window-size-listener';
import './PathIndicators.scss';

// TODO: refresh stage card array after editing path

/* stage cards are targeted by className: stage-card_[stage card object id] */

// line drawing parameters
const lineWidth = 2;
const backArrowColor = '#C00';
const sideArrowColor = 'var(--darkGrey)';
const rejectBorderStyle = 'dashed';
const sideTaskBorderStyle = 'dotted';

// component
const PathIndicators = () => {
	const { template } = useTemplateContext();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [resizeCount, setResizeCount] = useState(0);
	const stages: Stage[] = flattenStages(template as Flattenable, true);

	// pull out template stages for reference
	// const mainStages = template?.stages.map((stage) => stage._id);

	const getStageType = (stageId: string) => {
		const stage = stages.filter((stage) => stage._id === stageId)[0];
		return stage?.type ? stage?.type : '';
	};

	// flatten/merge for output loop
	const backrefs: any[] = stages.flatMap((stage) => {
		return {
			stageId: stage._id,
			transitions: stage.transitions.flat(),
		};
	});

	const xx: any[] = [];
	stages.forEach((stage) => {
		stage.transitions.forEach((t) => {
			xx.push({
				title: stage.title,
				stageId: stage._id,
				type: stage.type,
				targetStage: t.targetStage,
				transitionType: t.type,
			});
		});
	});

	// build lineto output from refs arr
	const output = React.useMemo(
		() => (
			<div>
				<WindowSizeListener
					onResize={(windowsize) => {
						// update state to redraw.
						// resize listener throttled at 100ms update cycle
						setResizeCount((resizeCt) => resizeCt + 1);
					}}
				></WindowSizeListener>

				{/* eslint-disable-next-line */}
				{xx.map((r, index) => {
					let arrowColor =
						r.transitionType === 'backward' ? backArrowColor : sideArrowColor;

					// build the path type string to compare later on
					const pathDefinition: string = `${getStageType(r.stageId)} stage ${
						r.transitionType
					} to ${getStageType(r.targetStage)}`;

					// console.log(pathDefinition);

					let mainToSideTask, mainBackward, sideBackward, sideToSideTask;

					// side task paths run inside
					mainToSideTask = (
						<div key={`path_${index}`} className="path-indicator">
							<LineTo
								delay={1}
								from={`stage-card_${r.stageId}`}
								fromAnchor={`0% 50%`}
								to={`stage-card_${r.stageId}`}
								toAnchor={`-10% 50%`}
								borderColor={arrowColor}
								borderWidth={lineWidth}
								borderStyle={sideTaskBorderStyle}
								within="stage-editor"
							/>

							<LineTo
								delay={1}
								from={`stage-card_${r.stageId}`}
								fromAnchor={`-10% 50%`}
								to={`stage-card_${r.targetStage}`}
								toAnchor={`110% 60%`}
								borderColor={arrowColor}
								borderWidth={lineWidth}
								borderStyle={sideTaskBorderStyle}
								within="stage-editor"
							/>

							<LineTo
								delay={1}
								from={`stage-card_${r.targetStage}`}
								fromAnchor={`110% 60%`}
								to={`stage-card_${r.targetStage}`}
								toAnchor={`100% 60%`}
								borderColor={arrowColor}
								borderWidth={lineWidth}
								borderStyle={sideTaskBorderStyle}
								within="stage-editor"
								className="end-segment side-task-path"
							/>
						</div>
					);

					sideToSideTask = (
						<div key={`path_${index}`} className="path-indicator">
							<LineTo
								delay={1}
								from={`stage-card_${r.stageId}`}
								fromAnchor={
									// eslint-disable-next-line eqeqeq
									getStageType(r.stageId) == 'main' ? `0 50%` : `100% 50%`
								}
								to={`stage-card_${r.stageId}`}
								toAnchor={`110% 50%`}
								borderColor={arrowColor}
								borderWidth={lineWidth}
								borderStyle={sideTaskBorderStyle}
								within="stage-editor"
							/>

							<LineTo
								delay={1}
								from={`stage-card_${r.stageId}`}
								fromAnchor={`110% 50%`}
								to={`stage-card_${r.targetStage}`}
								toAnchor={`110% 45%`}
								borderColor={arrowColor}
								borderWidth={lineWidth}
								borderStyle={sideTaskBorderStyle}
								within="stage-editor"
							/>

							<LineTo
								delay={1}
								from={`stage-card_${r.targetStage}`}
								fromAnchor={`110% 45%`}
								to={`stage-card_${r.targetStage}`}
								toAnchor={`100% 45%`}
								borderColor={arrowColor}
								borderWidth={lineWidth}
								borderStyle={sideTaskBorderStyle}
								within="stage-editor"
								className="end-segment side-task-path"
							/>
						</div>
					);

					// main backward lines run on right outside
					mainBackward = (
						<div key={`path_${index}`} className="path-indicator">
							<LineTo
								delay={1}
								from={`stage-card_${r.stageId}`}
								fromAnchor={`100% 50%`}
								to={`stage-card_${r.stageId}`}
								toAnchor={`105% 50%`}
								borderColor={arrowColor}
								borderWidth={lineWidth}
								borderStyle={rejectBorderStyle}
								within="stage-editor"
							/>

							<LineTo
								delay={1}
								from={`stage-card_${r.stageId}`}
								fromAnchor={`105% 50%`}
								to={`stage-card_${r.targetStage}`}
								toAnchor={`105% 60%`}
								borderColor={arrowColor}
								borderWidth={lineWidth}
								borderStyle={rejectBorderStyle}
								within="stage-editor"
							/>

							<LineTo
								delay={1}
								from={`stage-card_${r.targetStage}`}
								fromAnchor={`105% 60%`}
								to={`stage-card_${r.targetStage}`}
								toAnchor={`100% 60%`}
								borderColor={arrowColor}
								borderWidth={lineWidth}
								borderStyle={rejectBorderStyle}
								within="stage-editor"
								className="end-segment reject-path"
							/>
						</div>
					);

					// side backward lines run on left outside
					sideBackward = (
						<div key={`path_${index}`} className="path-indicator">
							<LineTo
								delay={1}
								from={`stage-card_${r.stageId}`}
								fromAnchor={`0% 50%`}
								to={`stage-card_${r.stageId}`}
								toAnchor={`-10% 50%`}
								borderColor={arrowColor}
								borderWidth={lineWidth}
								borderStyle={rejectBorderStyle}
								within="stage-editor"
							/>

							<LineTo
								delay={1}
								from={`stage-card_${r.stageId}`}
								fromAnchor={`-10% 50%`}
								to={`stage-card_${r.targetStage}`}
								toAnchor={`-10% 55%`}
								borderColor={arrowColor}
								borderWidth={lineWidth}
								borderStyle={rejectBorderStyle}
								within="stage-editor"
							/>

							<LineTo
								delay={1}
								from={`stage-card_${r.targetStage}`}
								fromAnchor={`-10% 55%`}
								to={`stage-card_${r.targetStage}`}
								toAnchor={`0% 55%`}
								borderColor={arrowColor}
								borderWidth={lineWidth}
								borderStyle={rejectBorderStyle}
								within="stage-editor"
								className="end-segment reject-path"
							/>
						</div>
					);

					// side backward lines run on left outside
					const subBackward = (
						<div key={`path_${index}`} className="path-indicator">
							<LineTo
								delay={1}
								from={`stage-card_${r.stageId}`}
								fromAnchor={`100% 50%`}
								to={`stage-card_${r.stageId}`}
								toAnchor={`122% 50%`}
								borderColor={arrowColor}
								borderWidth={lineWidth}
								borderStyle={rejectBorderStyle}
								within="stage-editor"
							/>

							<LineTo
								delay={1}
								from={`stage-card_${r.stageId}`}
								fromAnchor={`122% 50%`}
								to={`stage-card_${r.targetStage}`}
								toAnchor={`106% 55%`}
								borderColor={arrowColor}
								borderWidth={lineWidth}
								borderStyle={rejectBorderStyle}
								within="stage-editor"
							/>

							<LineTo
								delay={1}
								from={`stage-card_${r.targetStage}`}
								fromAnchor={`106% 55%`}
								to={`stage-card_${r.targetStage}`}
								toAnchor={`100% 55%`}
								borderColor={arrowColor}
								borderWidth={lineWidth}
								borderStyle={rejectBorderStyle}
								within="stage-editor"
								className="end-segment reject-path"
							/>
						</div>
					);

					// return corresponding path type
					switch (pathDefinition) {
						case 'single stage backward to single':
							return mainBackward;
						case 'single stage backward to parallel':
							return mainBackward;
						case 'main stage sideTask to side':
							return mainToSideTask;
						case 'side stage sideTask to side':
							return sideToSideTask;
						case 'sideTask stage backward to sideTask':
							return sideBackward;
						case 'substage stage backward to single':
							return subBackward;
						case 'substage stage backward to parallel':
							return subBackward;
						case 'parallel stage sideTask to sideTask':
							return mainToSideTask;
						case 'single stage sideTask to sideTask':
							return mainToSideTask;
						default:
							console.log('>>> NO PATH RETURNED');
							break;
					}
				})}
			</div>
		),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[backrefs]
	);

	return (
		// Still hard coded line for the moment
		<div>{output}</div>
	);
};

export default PathIndicators;
