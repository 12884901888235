import React from 'react';
import './LoadingIndicator.scss';

const LoadingIndicator = () => {
	return (
		<div className="no-freeze-spinner">
			<div id="no-freeze-spinner">
				<div>
					<i className="material-icons">account_tree</i>
					<i className="material-icons">photo_library</i>
					<i className="material-icons">description</i>
					<i className="material-icons">account_circle</i>
					<div></div>
				</div>
			</div>
		</div>
	);
};

export default LoadingIndicator;
