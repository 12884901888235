import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useKeybind } from '../../hooks';
import { getModalTransition } from '../modal.helpers';
import {
	Dialog,
	LeftPanel,
	ModalContent,
	RightPanel,
	TopPanel,
} from '../modal.styles';
import { Mask } from './Mask';

export type ModalTypes = 'modal' | 'right-panel' | 'left-panel' | 'top-panel';

export type ModalProps = {
	children?: any;
	isOpen: boolean;
	title: string;
	onClose: () => void;
	type?: ModalTypes;
	style?: any;
};

export const Modal = ({
	children,
	isOpen,
	title,
	style,
	...props
}: ModalProps) => {
	useKeybind('Escape', props.onClose);

	const modalProps = {
		isVisible: isOpen,
		transition: getModalTransition(props.type),
	};

	const PopupContent = () => (
		<>
			<ModalContent>
				<button onClick={() => props.onClose()}>
					<FontAwesomeIcon
						icon={faTimesCircle}
						inverse
						className="close-icon"
					/>
				</button>
				<h2>{title}</h2>
			</ModalContent>
			{children}
		</>
	);

	return (
		<>
			<Mask isVisible={isOpen} onClick={() => props.onClose()} />
			{!props.type && (
				<Dialog style={style} {...modalProps}>
					{' '}
					{PopupContent()}{' '}
				</Dialog>
			)}

			{props.type === 'left-panel' && (
				<LeftPanel {...modalProps}>{PopupContent()}</LeftPanel>
			)}

			{props.type === 'right-panel' && (
				<RightPanel {...modalProps}>{PopupContent()}</RightPanel>
			)}

			{props.type === 'top-panel' && (
				<TopPanel {...modalProps}>{PopupContent()}</TopPanel>
			)}
		</>
	);
};
