import { User } from '../../../../utils/auth';
import {
	Stage,
	Workflow,
	WorkflowBooleans,
	WorkflowTemplate,
} from '../types/workflow.types';
import { flattenStages } from './workflowStage.helpers';

export const getWorkflowBoolean = (
	workflowBoolean: keyof WorkflowBooleans,
	workflow: Workflow,
	currentUserGroups?: any[],
	currentUser?: User,
	templateUsed?: WorkflowTemplate
): boolean => {
	switch (workflowBoolean) {
		case 'isCurrentUserInvolved':
			return (
				!!flattenStages(workflow)
					?.flatMap((s) => s)
					.some((stage: Stage) =>
						stage.owners?.some(
							(m) =>
								currentUserGroups?.some((grp) => grp._id === m._id) ||
								m._id === currentUser?._id
						)
					) ||
				!!workflow?.owners?.some((m) => m._id === currentUser?._id) ||
				!!(templateUsed?.followers ?? [])?.some(
					(follower) => follower._id === currentUser?._id
				) ||
				(currentUserGroups || [])?.some((group) =>
					(templateUsed?.followers ?? [])?.some((m) => m._id === group._id)
				) ||
				((currentUserGroups || [])?.some((group) =>
					workflow?.owners?.some((m) => m._id === group._id)
				) as boolean) ||
				workflow.createdBy._id === currentUser?._id
			);
		case 'isOwnedByCurrentUser':
			if (currentUserGroups !== undefined)
				return (
					(currentUserGroups || []).some(
						(group) =>
							workflow.owners.some((m) => m._id === group._id) ||
							workflow.owners.some((m) => m._id === currentUser?._id)
					) ||
					(flattenStages(workflow)
						?.flatMap((s) => s)
						.some((stage) =>
							stage?.owners?.some((m) => m._id === currentUser?._id)
						) as boolean)
				);
			return flattenStages(workflow)
				?.flatMap((s) => s)
				.some((stage) =>
					stage.owners?.some((m) => m._id === currentUser?._id)
				) as boolean;
		case 'isCompleted':
			return workflow.status === 'completed';
		case 'isActive':
			return workflow.status === 'active';
		case 'hasAStageOwnedByCurrentUser':
			return !!workflow?.stages?.some((stage) =>
				stage.substages?.length
					? !!stage.substages
							?.flatMap((s) => s)
							.some(
								(substage) =>
									!!substage.owners?.some(
										(substageOwner) =>
											substageOwner._id === currentUser?._id ||
											currentUserGroups?.some(
												(grp) => grp._id === substageOwner._id
											)
									)
							)
					: !!(
							stage.owners &&
							stage.owners?.some((owner) => owner._id === currentUser?._id)
					  ) ||
					  !!currentUserGroups?.some((grp) =>
							stage.owners?.some((ownr) => ownr._id === grp._id)
					  )
			);
		case 'isCurrentUserFollower':
			return (templateUsed?.followers?.some(
				(m) => m._id === currentUser?._id
			) ||
				(currentUserGroups || [])?.some((group) =>
					templateUsed?.followers?.some(
						(follower) => follower._id === group._id
					)
				)) as boolean;
		case 'isCreatedByCurrentUser':
			return workflow.createdBy._id === currentUser?._id;

		default:
			return false;
	}
};
export const canSetStageOwners = (
	stage: Stage,
	currentUser: User,
	groupsForCurrentUser: any[]
) =>
	groupsForCurrentUser.some((group) =>
		stage?.owners?.some((owner) => owner._id === group._id)
	) ||
	stage?.owners?.some((m) => m._id === currentUser._id) ||
	currentUser.isAdmin;
