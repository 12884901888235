import React, { useState } from 'react';
import { FormFeedback } from 'reactstrap';

import { stubTrue } from '../../utils/common';
import DownshiftMultiFreeform from '../downshift-select/downshift-multi-freeform.component';

import { SelectionActions } from '../downshift-select/downshift.interfaces';
import { EntityMetadata } from '../workflow/workflows/types/workflow.types';

// interface
interface TagInputProps {
	metadata: EntityMetadata;
	onChange?: (metadata: EntityMetadata) => void;
}

// component
const TagInput = ({ metadata, onChange }: TagInputProps) => {
	const [editingTags, setEditingTags] = React.useState(metadata?.tags || []);
	const [valid, setValid] = useState(true);

	const removeTag = (tag: string) => {
		const updated = editingTags?.filter((tagEdited) => tagEdited !== tag);
		setEditingTags(updated);
		if (onChange) onChange({ ...metadata, tags: updated } as EntityMetadata);
	};

	const addTag = (tag: string) => {
		if (tag && editingTags?.indexOf(tag.toString()) <= -1) {
			setValid(true);
			setEditingTags([...editingTags, tag]);
			if (onChange)
				onChange({
					...metadata,
					tags: [...editingTags, tag],
				} as EntityMetadata);
		} else {
			setValid(false);
		}
	};

	const selectionActions: SelectionActions<string, string> = {
		select: addTag,
		unselect: removeTag,
	};

	const selectionState = {
		selection: editingTags,
		options: [],
		searchPredicate: stubTrue,
	};

	return (
		<>
			<DownshiftMultiFreeform
				label="Tags"
				placeholder="Add a tag..."
				selectionState={selectionState}
				selectionActions={selectionActions}
			/>
			<FormFeedback invalid="true" className={valid ? 'd-none' : 'd-block'}>
				That tag was already added
			</FormFeedback>
		</>
	);
};

export default TagInput;
