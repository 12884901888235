import {
	faInbox,
	faLongArrowAltUp,
	faPlusSquare,
	faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'reactstrap';
import { useThemeContext } from 'context/useThemeContext';
import { UserAvatar } from 'components/admin-dashboard/components/user-details.styled-components';
import { useAuthContext } from 'utils/auth';
import { RomeSwal } from 'components/alert';
import { OutlineButton } from 'components/buttons.styled-components';
import RenderWhen from 'components/render-when.component';
import { AnchorSpan } from 'components/dashboard.component/components/proxy-panel.styled.component';
import moment from 'moment';
import React, { useState } from 'react';
import {
	ButtonGroup,
	CardBody,
	CardFooter,
	CardHeader,
	FormFeedback,
	Input,
	UncontrolledTooltip,
} from 'reactstrap';
import styled from 'styled-components';
import { useWorkflowContext } from 'context/useWorkflowStore';
import { Workflow } from 'components/workflow/workflows/types';
import { OutlinedCircleButton } from './action-button.styled-components';

const RecursiveWorkflowComment = ({
	comment,
	workflow,
}: {
	comment: any;
	workflow: Workflow;
}) => {
	const { addWorkflowComment, deleteWorkflowComment } = useWorkflowContext();
	const { currentUser } = useAuthContext();
	const { defaults } = useThemeContext();
	const addWorkflowCommentAsync = async (
		comment: { message: string; parentId?: string },
		workflowId: string
	) => {
		await addWorkflowComment(comment, workflowId);
	};

	const deleteWorkflowCommentAsync = async (
		commentId: string,
		workflowId: string
	) => await deleteWorkflowComment(commentId, workflowId);

	const [showReply, setShowReply] = React.useState(false);
	const [reply, setReply] = React.useState('');

	const promptForDelete = async (comment: any) => {
		const { isConfirmed } = await RomeSwal.fire({
			confirmButtonText: 'Delete Comment',
			icon: 'question',
			title: 'Are you sure you want to delete the comment?',
			text: comment?.replies?.length
				? ' This will delete all of the original comments replies. This action is not reversible.'
				: 'This action is not reversible.',
			cancelButtonText: 'Cancel',
			showCancelButton: true,
		});

		if (isConfirmed) {
			await deleteWorkflowCommentAsync(comment._id, workflow._id);
		}
	};

	return (
		<li className="cmmnt">
			<div className="avatar">
				<AnchorSpan>
					<UserAvatar user={comment.createdBy} />
				</AnchorSpan>
			</div>
			<div>
				<header>
					<AnchorSpan>
						{comment.createdBy.givenName} {comment.createdBy.familyName}
					</AnchorSpan>{' '}
					- <span>posted {moment(comment.createdAt).fromNow()}</span>{' '}
					{comment.createdBy._id === currentUser._id && (
						<AnchorSpan
							onClick={() => promptForDelete(comment)}
							style={{ color: 'darkred' }}
						>
							<FontAwesomeIcon icon={faTrash} />
						</AnchorSpan>
					)}
				</header>
				<p>{comment.message}</p>
				<ButtonGroup>
					{showReply === false && comment.createdBy._id !== currentUser._id && (
						<AnchorSpan onClick={() => setShowReply(true)}>Reply</AnchorSpan>
					)}
					{showReply && (
						<AnchorSpan onClick={() => setShowReply(false)}>Cancel</AnchorSpan>
					)}
				</ButtonGroup>
			</div>
			{showReply && (
				<div className="pb-2">
					<Input
						type="textarea"
						rows={4}
						placeholder={'Add a comment...'}
						onChange={(e) => setReply(e.target.value)}
					/>
					<RenderWhen when={!!reply}>
						<OutlineButton
							onClick={() =>
								addWorkflowCommentAsync(
									{ message: reply, parentId: comment._id },
									workflow._id
								)
							}
						>
							Add Comment
						</OutlineButton>
					</RenderWhen>
				</div>
			)}
			{comment.replies.map((reply: any) => (
				<div key={reply._id} style={{ position: 'relative' }}>
					<FontAwesomeIcon
						style={{ left: -30, color: defaults?.primary }}
						className={'position-absolute'}
						icon={faLongArrowAltUp}
					/>
					<RecursiveWorkflowComment workflow={workflow} comment={reply} />
				</div>
			))}
		</li>
	);
};

export const WorkflowCommentPanel = ({ workflow }: { workflow: Workflow }) => {
	const [commentMessage, setCommentMessage] = useState('');
	const { addWorkflowComment } = useWorkflowContext();
	const [valid, setValid] = React.useState(true);
	const addWorkflowCommentAsync = async (
		comment: { message: string; parentId?: string },
		workflowId: string
	) => {
		await addWorkflowComment(comment, workflowId);
		setCommentMessage('');
		setValid(true);
	};

	const onKeyPress = (event: any) => {
		if (event && event.key === 'Enter') {
			event.stopPropagation();
			event.preventDefault();
			setCommentMessage(event.target.value);
			if (!commentMessage) {
				setValid(false);
				return;
			}

			setValid(true);
			addWorkflowCommentAsync(
				{ message: commentMessage },
				workflow._id
			).then(() => setCommentMessage(''));
		}
	};
	const Wrapper = styled.div`
        blockquote, q { quotes: none; }
        blockquote:before, blockquote:after, q:before, q:after { content: ''; content: none; }
        strong { font-weight: bold; }

        table { border-collapse: collapse; border-spacing: 0; }
        img { border: 0; max-width: 100%; }
        h1 { font-family: 'Wellfleet', 'Trebuchet MS', Tahoma, Arial, sans-serif; font-size: 2.85em; line-height: 1.6em; font-weight: normal; color: #756f8b; text-shadow: 0px 1px 1px #fff; margin-bottom: 21px; }

        p { font-family: Arial, Geneva, Verdana, sans-serif; font-size: 14px; line-height: 1.42em; margin-bottom: 12px; font-weight: normal; color: #656565; }

        a { color: #896dc6; text-decoration: none; }
        a:hover { text-decoration: underline; }

        /* page layout structure */
        #w { display: block; width: 700px; margin: 0 auto; padding-top: 35px; }

        #container {
        display: block;
        width: 100%;
        background: #fff;
        padding: 14px 20px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        -webkit-box-shadow: 1px 1px 1px rgba(0,0,0,0.3);
        -moz-box-shadow: 1px 1px 1px rgba(0,0,0,0.3);
        box-shadow: 1px 1px 1px rgba(0,0,0,0.3);
        `;
	const Thread = styled.ul`
		list-style: none;
		display: block;

		.cmmnt,
		ul .cmmnt,
		ul ul .cmmnt {
			padding: 5px 0px;
			display: block;
			position: relative;
			padding-left: 45px;
			&:first-child {
				border-top: none;
			}
			border-top: 1px solid #ddd;
		}
		.cmmnt .avatar {
			position: absolute;
			top: 8px;
			left: 0;
		}
		.cmmnt .avatar * {
			-webkit-border-radius: 3px;
			-moz-border-radius: 3px;
			border-radius: 3px;
			-webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.44);
			-moz-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.44);
			box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.44);
			-webkit-transition: all 0.4s linear;
			-moz-transition: all 0.4s linear;
			-ms-transition: all 0.4s linear;
			-o-transition: all 0.4s linear;
			transition: all 0.4s linear;
		}
		.cmmnt .avatar span:hover > * {
			opacity: 0.77;
			cursor: pointer;
		}
	`;

	return (
		<Card>
			<CardHeader>
				Workflow Comments <FontAwesomeIcon icon={faInbox} />
			</CardHeader>
			<CardBody>
				<Wrapper>
					<Thread>
						{!workflow?.comments?.length && (
							<li>No comments have been added to the workflow.</li>
						)}
						{workflow?.comments?.map((comment) => (
							<RecursiveWorkflowComment
								key={comment._id}
								comment={comment}
								workflow={workflow}
							/>
						))}
					</Thread>
				</Wrapper>
			</CardBody>
			<CardFooter>
				<div className="d-flex">
					<Input
						onKeyDown={onKeyPress}
						type="textarea"
						rows={4}
						placeholder={'Add a comment...'}
						onChange={(e) => setCommentMessage(e.target.value)}
						value={commentMessage}
					/>
					{commentMessage && (
						<>
							<UncontrolledTooltip target={'addWorkflowCommentTooltip'}>
								Add Comment
							</UncontrolledTooltip>
							<OutlinedCircleButton
								id={'addWorkflowCommentTooltip'}
								className="p-0"
								onClick={() =>
									addWorkflowCommentAsync(
										{ message: commentMessage },
										workflow._id
									)
								}
							>
								<FontAwesomeIcon icon={faPlusSquare} />
							</OutlinedCircleButton>
						</>
					)}
				</div>
				<FormFeedback className={!valid ? 'd-block' : 'd-none'} color="danger">
					This field is required.
				</FormFeedback>
			</CardFooter>
		</Card>
	);
};
