import * as R from 'ramda';
import { Workflow, WorkflowCollection } from '../types';

export const getWorkflowCollection = (
	workflow?: Workflow,
	collections?: WorkflowCollection[]
) => {
	if (!workflow || !collections) return;
	return collections.find(
		R.pipe(R.prop('workflows'), R.includes(workflow._id))
	);
};

export const getProjectName = (
	workflow?: Workflow,
	collections?: WorkflowCollection[]
) => {
	const collection = getWorkflowCollection(workflow, collections);
	// console.log('collection', collection);
	return collection?.title || '—';
};
