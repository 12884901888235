import { SiteDefaults } from 'context/useThemeContext';
import storageModel from 'utils/models/StorageModel';
import { Button } from 'reactstrap';
import styled from 'styled-components';

import circularButton from '../../../../core-ui/components/circular-button';
import themeStore from '../../../../core-ui/models/ThemeStore';

const { colors } = themeStore.selectedTheme;
const defaults = (JSON.parse(storageModel.get('SITE_DEFAULTS') as string) ||
	{}) as SiteDefaults;
// styled components
export const ButtonWrapper = styled.div`
	position: absolute;
	border-radius: 10em !important;
	bottom: 0.25em;
	right: 0.25em;
	z-index: 2;
`;

export const EditButton = styled(Button)`
	${circularButton};

	&:hover {
		background-color: ${colors.success} !important;
	}

	&.selected {
		color: ${colors.success} !important;
	}
`;

export const DeleteButton = styled(Button)`
	${circularButton};
	margin-left: 8px;

	&:hover {
		background-color: ${defaults?.danger} !important;
	}
`;

export const AddSubstageButton = styled(Button)`
	${circularButton};
	margin-left: 8px;

	&:hover {
		background-color: ${colors.success} !important;
	}
`;
